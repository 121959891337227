import { apiPath } from "@/config/apiPath";
import axios from "@/util/axios";
import { getQueryString } from "@/util/helper";

const state = {
  brokerRequirementProperty: [],
  brandPropertyStatusOptions: [],
};

const getters = {
  getBrokerProperty: (state) => state.externalProperty,
  getBrandPropertyStatusOptions: (state) => state.brandPropertyStatusOptions,
};

const mutations = {
  setAllBrokerProperty: (state, payload) => (state.externalProperty = payload),
  doNothing: (state) => state,
  setBrandPropertyStatusOptions: (state, payload) => (state.brandPropertyStatusOptions = payload),
};

const actions = {
  /**
   * @description Get All external property
   * @param {Number} data - external property Object
   * @returns Promise
   */
  async fetchAllBrokerRequirementForProperties({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.brokerProperties.getAllBrokerProperty+ `?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBrokerProperty", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Get all Broker properties
   * @param {Object} data - Broker properties Id
   * @returns Promise
   */
  async fetchAllBrokerPropertyByRequirementId({ commit }, options) {
    console.log(options, 'options');
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.brokerProperties.getAllBrokerPropertiesByRequirementId+`${options.id}?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBrokerProperty", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Accept/Reject Broker Submitted Property
   * @param {Object} data - status, note
   * @returns Promise
   */
  async updateBrokerPropertiesStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
        axios
            .put(apiPath.brokerProperties.updateStatus+`${data.id}`, data)
            .then(async (res) => {
                if (res.data?.success) {
                  console.log("res ->", res.data);
                    await commit("doNothing");
                    resolve(res);
                }
            })
            .catch((e) => {
                reject(e);
            });
    });   
  },

  /**
   * @description Get all Brand Properties Status Options
   * @returns Promise
   */
  async fetchBrandPropertyStatusOptions({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.brokerProperties.getPropertyStatusOptions)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setBrandPropertyStatusOptions", res.data?.data?.brand_action_status);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
