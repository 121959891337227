import { getQueryString } from "@/util/helper";
import axios from "@/util/axios";
import { apiPath } from "@/config/apiPath";

const loadFilterValue = () => {
  const filterValue = JSON.parse(localStorage.getItem("filterValue"));
  return filterValue
    ? filterValue
    : {
      filter: false,
      city: "",
      state: "",
      pincode: "",
      country: ""
    };
};

const state = {
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
  filterValue: loadFilterValue(),
  landlord: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getFilterValue: (state) => {
    return state.filterValue;
  },
  getAllLandlord: (state) => state.landlord,
};

const mutations = {
  setFilterValue: (state, payload) => {
    state.filterValue = payload;
    localStorage.setItem("filterValue", JSON.stringify(payload));
  },
  doNothing: (state) => state,
  setAllLandlord: (state, payload) => (state.landlord = payload),
  setAllLandlordProperties: (state, payload) => (state.landlord = payload),
  addToLandlord: (state, payload) => state.landlord.unshift(payload),
  updateToLandlord: (state, updatedData) => {
    state.landlord.forEach((landlord) => {
      if (landlord.id == updatedData.id) {
        // Update Data
        landlord.full_name = updatedData.full_name;
        landlord.email = updatedData.email;
        landlord.mobile = updatedData.mobile;
        landlord.city = updatedData.city;
        landlord.street = updatedData.street;
        landlord.state = updatedData.state;
        landlord.pincode = updatedData.pincode;
        landlord.role = updatedData.role;
      }
    });
  },
  removeLandlord: (state, id) => {
    state.landlord = state.landlord.filter((u) => u.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
  setPageAttributes(state, value) {
    state.pageAttributes = value;
  },
};

const actions = {
  updateFilterValue: ({ commit }, filterValue) => {
    commit("setFilterValue", filterValue);
  },
  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },
  /**
   * @description Get All Landlord
   * @param {Number} data - landlord Object
   * @returns Promise
   */

  async fetchAllLandlords({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.landlords.getAllLandlords + `?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllLandlord", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Get All Landlord Properties
   * @param {Number} data - landlord Object
   * @returns Promise
   */

  async fetchAllLandlordProperties({ commit }, id) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (id.options.all) {
        params = id.options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = id.options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.landlords.getAllLandlordProperties + `${id.id}?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllLandlord", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Update Landlord Profile
   * @param {Object} data - Update Landlord Object
   * @returns Promise
   */
  updateLandlord({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.landlords.updateLandlord + `${data._id}`, data)
        .then(async (res) => {
          if (res.data.success) {
            commit("updateToLandlord", data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description Search Landlord
   * @param {Number} data - Landlord Object
   * @returns Promise
   */

  searchLandlord({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter;
        params = { name, ...params };
      }

      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.landlords.searchLandlords + `?${url}`)
        .then((res) => {
          if (res.data.success) {
            commit("setAllLandlord", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove Landlord
   * @param {Object} data - Remove Landlord
   * @returns Promise
   */
  removeLandlordById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.landlords.removeLandlord + `${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removeLandlord", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Get Landlord Info by ID
   * @param {Number} id - Landlord ID
   * @returns Promise
   */
  fetchLandlordByID({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.landlords.getLandlordByID + `${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Create New Landlord
   * @param {Object} data - User data Object
   * @returns Promise
   */
  createLandlord({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.landlords.createLandlord, data)
        .then((res) => {
          if (res.data.success) {
            commit("addToLandlord", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Filter Landlord
   * @param {Number} id - pincode , state, city, country
   * @returns Promise
   */
  async filterLandlord({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = {
          ...options,
          state: state.filterValue.state,
          city: state.filterValue.city,
          pincode: state.filterValue.pincode,
          country: state.filterValue.country,
        };
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
          state: state.filterValue.state,
          city: state.filterValue.city,
          pincode: state.filterValue.pincode,
          country: state.filterValue.country,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.landlords.filterLandlords + `?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllLandlord", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
