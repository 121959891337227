import { getQueryString } from "@/util/helper";
import axios from "@/util/axios";
import { apiPath } from "@/config/apiPath";

const state = {
  proposalReq: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAllProposalRequest: (state) => state.proposalReq,
};

const mutations = {
  setAllProposalRequest: (state, proposalReq) => (state.proposalReq = proposalReq),
  doNothing: (state) => state,
  addToProposalRequest: (state, role) => state.proposalReq.unshift(role),
  updateProposalRequest: (state, updatedProp) => {
    state.proposalReq.forEach((proposal) => {
      if (proposal._id == updatedProp._id) {
        // Update Data
        proposal.stage_status = updatedProp.stage_status;
        proposal.comments = updatedProp.comments;
      }
    });
  },
};

const actions = {
  /**
   * @description Get all proposal requested
   * @param {Object} data - proposal requested Object
   * @returns Promise
   */
  async fetchAllRequestedPropertyByRequirementId({ commit }, options) {
    console.log(options, 'options');
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.proposal.getRequestedProposalByReqId+`${options.id}?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllProposalRequest", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Create Prop Request
   * @param {Object} data - Brand Requiremnt Object
   * @returns Promise
   */
  createPropRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.proposalRequest.createPropRequest, data)
        .then((res) => {
          if (res.data.success) {
            commit("addNewBrandRequirement", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update Proposal status
   * @param {Object} data - Update Proposal status
   * @returns Promise
   */
  updateProposalRequestByID({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(apiPath.proposalRequest.updateProposalRequest+`${data.id}/proposal/stage_status`, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("updateProposalRequest", data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Update Visit Info
   * @param {Object} data - Update Role Object
   * @returns Promise
   */
  updateVisit({ commit }, data) {
    console.log(data, 'data info');
    return new Promise((resolve, reject) => {
      axios.put(apiPath.proposalRequest.updateProposalRequest+`${data.id}/visit/stage_status`, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("updateProposalRequest", data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
