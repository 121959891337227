import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomePage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      requiredAuth: true,
      title: "Home",
    },
  },
  {
    path: "/brand-requirement",
    name: "brand-requirement",
    component: () =>
      import(
        /* webpackChunkName: "brand-requirement" */ "../views/BrandRequirementsPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Requirements",
    },
  },
  {
    path: "/brand-requirements/:id",
    name: "view-brand-requirement",
    component: () =>
      import(
        /* webpackChunkName: "view-brand-requirement" */ "../components/BrandRequirement/viewBrandRequirement.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "View Requirements",
    },
  },
  // {
  //   path: "/brand_requirements/public/:id",
  //   name: "public-brand-requirement",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "public-brand-requirement" */ "../components/BrandRequirement/brandRequirementPublic.vue"
  //     ),
  //   meta: {
  //     requiredAuth: false,
  //     title: "Add Public Property",
  //   },
  // },
  {
    path: "/roles",
    name: "roles",
    component: () =>
      import(
        /* webpackChunkName: "roles" */ "../views/RolesManagementPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Roles",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordPage",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      requireAuth: false,
      title: "Forgot Password",
    },
  },
  {
    path: "/company-page",
    name: "company-page",
    component: () =>
      import(/* webpackChunkName: "company-page" */ "@/views/CompanyPage.vue"),
    meta: {
      requiredAuth: true,
      title: "Company Page",
    },
  },
  {
    path: "/permissions",
    name: "permissions",
    component: () =>
      import(
        /* webpackChunkName: "permissions" */ "../views/PermissionManagementPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Permissions",
    },
  },
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/UsersManagementPage"),
    meta: {
      requiredAuth: true,
      title: "Users",
    },
  },
  {
    path: "/offices",
    name: "offices",
    component: () =>
      import(
        /* webpackChunkName: "offices" */ "../views/OfficeManagementPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Offices",
    },
  },
  {
    path: "/properties",
    name: "properties",
    component: () =>
      import(
        /* webpackChunkName: "properties" */ "../views/PropertyManagementPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Properties",
    },
  },
  {
    path: "/add-property",
    name: "add-property",
    component: () =>
      import(
        /* webpackChunkName: "add-property" */ "../components/PropertyManagement/pages/addPropertyMainPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Add Property",
    },
  },
  {
    path: "/properties/:id",
    name: "view-property",
    component: () =>
      import(
        /* webpackChunkName: "view-property" */ "../components/PropertyManagement/viewPropertyPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "View Property",
    },
  },

  {
    path: "/property/edit/:id",
    name: "edit-property",
    component: () =>
      import(
        /* webpackChunkName: "edit-property" */ "../components/PropertyManagement/pages/editPropertyMainPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Edit Property",
    },
  },
  {
    path: "/login",
    name: "login-page",
    component: () =>
      import(/* webpackChunkName: "login-page" */ "../views/LoginPage.vue"),
    meta: {
      requiredAuth: false,
      title: "Login",
    },
  },
  {
    path: "/smart-search",
    name: "smart-search",
    component: () =>
      import(
        /* webpackChunkName: "smart-search" */ "../views/SmartSearchPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Smart Search",
    },
  },
  {
    path: "/users/:id",
    name: "view-user",
    component: () =>
      import(
        /* webpackChunkName: "View-user" */ "@/components/UsersManagement/viewUser.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "User Info",
    },
  },
  {
    path: "/profile",
    name: "profile-page",
    component: () =>
      import(/* webpackChunkName: "profile-page" */ "../views/ProfilePage.vue"),
    meta: {
      requiredAuth: true,
      title: "My Profile",
    },
  },
  {
    path: "/agprop-ai",
    name: "agprop-ai",
    component: () =>
      import(/* webpackChunkName: "agprop-ai" */ "../views/AGPAiPage.vue"),
    meta: {
      requiredAuth: true,
      title: "agprop AI",
    },
  },
  {
    path: "/landlords",
    name: "landlords",
    component: () =>
      import(
        /* webpackChunkName: "LandlordsPage" */ "@/views/LandlordManagementPage.vue"
      ),
    meta: {
      requireAuth: true,
      title: "Landlords",
    },
  },
  {
    path: "/brokers",
    name: "brokers",
    component: () =>
      import(
        /* webpackChunkName: "brokersPage" */ "@/views/BrokerManagementPage.vue"
      ),
    meta: {
      requireAuth: true,
      title: "Brokers",
    },
  },
  // {
  //   path: "/external-properties/:id",
  //   name: "view-externalProperty",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "ExternalPropertyPage" */ "@/components/ExternalProperty/viewExternalProperty.vue"
  //     ),
  //   meta: {
  //     requireAuth: true,
  //     title: "externalProperty",
  //   },
  // },
  // {
  //   path: "/external-property",
  //   name: "externalProperty",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "ExternalPropertyPage" */ "@/views/ExternalProperty.vue"
  //     ),
  //   meta: {
  //     requireAuth: true,
  //     title: 'External Property'
  //   },
  // },

  {
    path: "/broker-property",
    name: "brokerProperty",
    component: () =>
      import(
        /* webpackChunkName: "BrokerPropertyPage" */ "@/views/BrokerProperties.vue"
      ),
    meta: {
      requireAuth: true,
      title: 'Broker Property'
    },
  },
  {
    path: "/brokers/:id",
    name: "view-broker",
    component: () =>
      import(
        /* webpackChunkName: "View-broker" */ "@/components//BrokerManagement/viewBroker.vue"
      ),
    meta: {
      requireAuth: true,
      title: "Broker Info",
    },
  },
  {
    path: "/landlords/:id",
    name: "view-landlord",
    component: () =>
      import(
        /* webpackChunkName: "View-landlord" */ "@/components/LandlordManagement/viewLandlord.vue"
      ),
    meta: {
      requireAuth: true,
      title: "Landlord Info",
    },
  },
  {
    path: '/sign-up',
    name: 'signup-page',
    component: () => import(/* webpackChunkName: "singup" */ '@/views/SignUpPage.vue'),
    meta: {
      requiredAuth: false,
      title: 'Sign-up',
      hideFooter: true
    }
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions-page',
    component: () => import(/* webpackChunkName: "login" */ '../views/TermsPage.vue'),
    meta: {
      requiredAuth: false,
      title: 'Terms and conditions'
    }
  },
  {
    path: '/policy',
    name: 'policy-page',
    component: () => import(/* webpackChunkName: "login" */ '../views/PolicyPage.vue'),
    meta: {
      requiredAuth: false,
      title: 'Policy'
    }
  },
  {
    path: '/payment-terms',
    name: 'payment-terms-page',
    component: () => import(/* webpackChunkName: "login" */ '../views/PaymentTermPage.vue'),
    meta: {
      requiredAuth: false,
      title: 'Payment Terms'
    }
  },
  {
    path: "/brand-requirement/properties/:reqId/:id/",
    name: "brand-view-property",
    component: () =>
      import(
        /* webpackChunkName: "view-property" */ "../components/BrandRequirement/viewPropertyPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "View Property",
    },
  },
  {
    path: '/redirect',
    name: 'RedirectPage',
    component: () => import(/* webpackChunkName: "RedirectPage" */ '../views/RedirectPage.vue'),
    meta: {
      requiredAuth: false,
      title: 'Redirect Page'
    }
  },
  { 
    path: '/notification-redirect',
    name: 'notification-redirect',
    component: () => import('../views/NotificationRedirect.vue'),
    meta: {
      requiredAuth: false,
      title: 'notification redirect'
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  localStorage.setItem("routeDetail", JSON.stringify({ to: to.path, from: from.path }));
  let isAuth = localStorage.getItem("accessToken");
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (!isAuth) {
      next("/login");
    } else {
      document.title = to.meta.title + " - PropVision | agprop";
      next();
    }
  } else {
    document.title = to.meta.title + " - PropVision | agprop";
    next();
  }
});

export default router;
