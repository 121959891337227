import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import generalFunctions from './mixins/getFunc'
// import * as Sentry from '@sentry/vue';
import './style.css'
Vue.config.productionTip = false
Vue.mixin(generalFunctions)

// Sentry.init({
//   Vue,
//   dsn: 'https://785058a86e904bcf92821e4cdb182276@o4505070422589440.ingest.sentry.io/4505070424358912',
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

